/* figtree-regular - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 400;
  src: url('../Webfonts/figtree-v5-latin-regular.woff2') format('woff2');
}
/* figtree-italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 400;
  src: url('../Webfonts/figtree-v5-latin-italic.woff2') format('woff2');
}
/* figtree-500 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 500;
  src: url('../Webfonts/figtree-v5-latin-500.woff2') format('woff2');
}
/* figtree-500italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 500;
  src: url('../Webfonts/figtree-v5-latin-500italic.woff2') format('woff2');
}
/* figtree-600 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 600;
  src: url('../Webfonts/figtree-v5-latin-600.woff2') format('woff2');
}
/* figtree-600italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 600;
  src: url('../Webfonts/figtree-v5-latin-600italic.woff2') format('woff2');
}
/* figtree-700 - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: normal;
  font-weight: 700;
  src: url('../Webfonts/figtree-v5-latin-700.woff2') format('woff2');
}
/* figtree-700italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 700;
  src: url('../Webfonts/figtree-v5-latin-700italic.woff2') format('woff2');
}
/* figtree-800italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 800;
  src: url('../Webfonts/figtree-v5-latin-800italic.woff2') format('woff2');
}
/* figtree-900italic - latin */
@font-face {
  font-display: swap;
  font-family: 'Figtree';
  font-style: italic;
  font-weight: 900;
  src: url('../Webfonts/figtree-v5-latin-900italic.woff2') format('woff2');
}
