.frame-type-theme_teaser_pages {
  --page-columns: 2;
  --font-size: 20px;
  --column-max-width: 230px;

  @include media-breakpoint-up(md) {
    --page-columns: 3;
    --column-max-width: 224px;
  }

  @include media-breakpoint-up(xl) {
    --page-columns: 5;
    --font-size: 22px;
    --column-max-width: 230px;
  }

  @include media-breakpoint-up(ultrawide) {
    --page-columns: 6;
    --font-size: 24px;
  }

  .list-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max-content, var(--column-max-width)));
    grid-auto-rows: 1fr;
    justify-content: center;

    &__item {
      &-image {
        width: 133px;
        aspect-ratio: 1/1;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &-link {
        font-size: var(--font-size);
        font-weight: 700;
        color: __get($theme-colors, 'bodycolor');
      }
    }

    & + div a {
      font-size: 18px;
    }
  }
}
