.solr {
  &-search {
    &__wrapper {
      top: 0;
      right: 0;
      box-shadow: $focus-ring-box-shadow;
    }

    &__form {
      &:not(&-static) {
        display: none;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        height: calc(100% - 8px);

        @include media-breakpoint-up(xl) {
          height: 100%;
        }

        &.open {
          display: flex;
          background: linear-gradient(90deg, rgba(255, 255, 255, 50%) 0%, __get($theme-colors, 'white') 100%);
        }

        input {
          --solr-input-max-width: calc(100vw - 152px);
          max-width: var(--solr-input-max-width);
          width: auto;
          border: none;

          @include media-breakpoint-up(md) {
            width: 250px;
          }

          &:focus {
            border: none;
            box-shadow: none;
          }
        }
      }

      &-static {
        input {
          &:focus {
            border-color: transparent;
            box-shadow: none;
          }

          @include media-breakpoint-up(xl) {
            font-size: px-to-rem(22px);
          }
        }
      }
    }

    &__list-container {
      background-color: __get($theme-colors, 'white');
      box-shadow: $box-shadow;
      z-index: 1000;
      top: calc(100% + 5px);
      width: 100%;

      .tx-solr-suggest-box {
        --max-navigation-height: calc(100dvh - 76px);
        max-height: var(--max-navigation-height);
        overflow: auto;

        @include media-breakpoint-up(xl) {
          --max-navigation-height: calc(100dvh - 150px);
        }

        ul {
          @extend .list-unstyled, .py-qg;

          &:first-of-type {
            border-bottom: 1px solid $grey-200;
          }

          li {
            display: flex;

            a {
              color: __get($theme-colors, 'bodycolor');
              text-decoration: none;
              font-size: px-to-rem(18px);
              padding: $grid-gutter-width * 0.375 $grid-gutter-width * 0.5;
              width: 100%;
              --focus-ring-outline-offset: -2px;

              @include media-breakpoint-up(xl) {
                font-size: px-to-rem(20px);
              }

              &:hover,
              &.hover {
                background-color: __get($theme-colors, 'action-1');
              }

              mark {
                background-color: transparent;
                color: __get($theme-colors, 'primary');
                padding: 0;
              }
            }
          }
        }
      }
    }

    &.static {
      border: $input-border-width solid transparent;
      @include transition($form-select-transition);

      &:focus-within {
        border-color: transparent;
        outline: 0;
        box-shadow: $form-select-focus-box-shadow;
      }
    }
  }

  &-result {
    &-found {
      font-weight: 600;
      @include media-breakpoint-up(xl) {
        --bs-body-font-size: #{px-to-rem(18px)};
      }
    }

    &-item {
      @include motion-safe {
        transition: all 0.2s ease-in-out;
      }

      &__topic {
        --min-size: 22;
        --max-size: 26;
      }

      &__content {
        font-size: px-to-rem(18px);

        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(20px);
        }

        .results-highlight {
          font-weight: 600;
        }
      }

      &__link {
        --bs-body-font-size: #{px-to-rem(16px)};
        color: $grey-700;
        word-wrap: break-word;
        overflow-wrap: break-word;
        font-size: var(--bs-body-font-size);
        font-weight: 500;

        @include media-breakpoint-up(xl) {
          --bs-body-font-size: #{px-to-rem(18px)};
        }
      }
    }
  }
}
