@use 'sass:math';

.blockquote {
  @include media-breakpoint-up(xl) {
    width: percentage(math.div(7, 12));

    --blockquote-font-size: #{px-to-rem(32px)};
  }

  &__quote {
    font-size: var(--blockquote-font-size);
    font-weight: var(--blockquote-font-weight);
    hyphens: auto;
    text-wrap: balance;

    &:before,
    &:after {
      font-size: var(--blockquote-font-size);
      font-weight: var(--blockquote-font-weight);
    }

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }
  }

  &__footer {
    *:not(.btn) {
      font-size: px-to-rem(18px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(20px);
      }

      font-style: normal;
    }
  }
}
