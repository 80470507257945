.frame-type-theme_quicklinks {
  .navigation {
    &__item {
      --flex-width: #{calc(100% / 2 - $grid-gutter-width * 0.25)};

      flex: 0 1 var(--flex-width);

      @include media-breakpoint-up(xl) {
        --flex-width: #{calc(100% / 3 - $grid-gutter-width * 0.67)};
      }

      @include media-breakpoint-up(ultrawide) {
        --flex-width: #{calc(100% / 4 - $grid-gutter-width * 0.75)};
      }

      display: block;

      &.hidden {
        @include media-breakpoint-between(md, xl) {
          display: none;
        }
      }
    }

    &__link {
      --bs-btn-icon-width: 36px;
      --bs-btn-icon-height: 24px;

      span {
        --bs-body-font-weight: 700;
        font-size: px-to-rem(18px);
        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(20px);
        }
      }
    }
  }

  .toggle-quicklinks {
    display: none;

    @include media-breakpoint-between(md, xl) {
      display: flex;
    }
  }
}
