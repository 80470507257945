.navigation {
  &__link {
    --bs-link-color-rgb: #{__get($theme-colors, 'bodycolor')};
    --focus-ring-outline-offset: -2px;
    padding: var(--navigation-padding-y) var(--navigation-padding-x);
    font-size: var(--navigation-font-size);
    background: var(--navigation-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transform: skew(var(--skew-negative), 0);
    position: relative;
    hyphens: auto;
    text-wrap: balance;

    .navigation-main & {
      @include media-breakpoint-down(xl) {
        justify-content: left;

        &.navigation__link-main:not(.navigation__toggle) {
          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            bottom: 0;
            background-color: var(--brand-color);
          }
        }
      }

      &.navigation__link-main {
        --navigation-font-size: #{px-to-rem(22px)};

        &.navigation__toggle {
          flex-basis: 0;
        }

        @include media-breakpoint-up(xl) {
          --navigation-font-size: #{px-to-rem(20px)};
        }
      }

      &:not(.navigation__link-main) {
        --bs-link-color-rgb: currentColor;
        --navigation-padding-x: #{$grid-gutter-width * 0.75};
        --navigation-padding-y: #{$grid-gutter-width * 0.5};

        @include media-breakpoint-up(xl) {
          --navigation-padding-x: #{$grid-gutter-width};
        }
      }
    }

    &-main:hover {
      --bs-link-color-rgb: #{__get($theme-colors, 'bodycolor')};
    }

    span {
      color: var(--bs-link-color-rgb);
      font-weight: var(--bs-body-font-weight);
      transform: skew(var(--skew), 0);
      text-wrap: nowrap;
    }
  }

  &__toggle {
    --toggle-btn-padding: #{$grid-gutter-width * 0.75};
    --toggle-btn-margin-bottom: #{$grid-gutter-width};
    --focus-ring-outline-offset: -2px;
    padding-left: var(--toggle-btn-padding);
    padding-right: var(--toggle-btn-padding);

    &-second-level.btn-back {
      margin-bottom: var(--toggle-btn-margin-bottom);
    }

    @include media-breakpoint-up(xl) {
      --toggle-btn-padding: #{$grid-gutter-width};

      &-second-level.btn-back {
        --toggle-btn-margin-bottom: #{$grid-gutter-width * 0.5 + px-to-rem(2px)};
        padding-bottom: $grid-gutter-width * 0.5;
        padding-left: $grid-gutter-width * 0.125;
      }
    }

    svg {
      color: __get($theme-colors, 'bodycolor');

      @include media-breakpoint-up(xl) {
        color: $grey-600;

        @include motion-safe {
          transition: transform 0.25s ease-in-out;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &[aria-expanded='true'],
      &:hover {
        svg {
          color: __get($theme-colors, 'bodycolor');
          transform: translateY(6px);
        }
      }
    }
  }

  &__item {
    .navigation-main & {
      &:not(:has(.navigation__link-main)) {
        &:hover {
          @include media-breakpoint-up(xl) {
            background-color: __get($theme-colors, 'action-1');
          }
        }
      }

      &:has(.navigation__link-main) {
        .navigation__link-main:not(.solr-search__toggle):after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1001;
          height: 5px;
          background: var(--brand-color);
        }

        @include media-breakpoint-up(xl) {
          &.active {
            color: var(--brand-color);
          }
        }

        &:not(.no-hover):hover,
        &:has([aria-expanded='true']),
        &.active {
          --bs-link-color-rgb: #{__get($theme-colors, 'bodycolor')};

          @include media-breakpoint-up(xl) {
            .navigation__link-main:after {
              content: '';
            }
          }
        }
      }
    }
  }

  // navigation wrapper
  &-wrapper {
    --max-navigation-height: calc(100dvh - 72px);
    z-index: 1001;
    top: 100%;
    right: 0;
    left: 0;
    position: absolute;
    overflow: auto;
    max-height: var(--max-navigation-height);

    &:has(.collapse__first-level.show) {
      @include media-breakpoint-down(xl) {
        overflow: hidden;
      }
    }

    @include media-breakpoint-up(md) {
      left: 50%;
    }

    @include media-breakpoint-up(xl) {
      position: unset;
      overflow: unset;
    }
  }

  &-inner {
    @include media-breakpoint-down(xl) {
      height: var(--max-navigation-height);
    }
  }

  // meta navigation
  &-meta {
    --navigation-font-size: #{px-to-rem(18px)};
    --navigation-padding-x: #{$grid-gutter-width * 0.75};
    --navigation-padding-y: #{px-to-rem(10px)};

    @include media-breakpoint-up(xl) {
      --navigation-font-size: #{px-to-rem(16px)};
      --navigation-padding-x: #{$grid-gutter-width * 0.5};
      --navigation-padding-y: #{$grid-gutter-width * 0.5};
    }

    &__list {
      --skew: 0deg;
      --skew-height: 48px;
      --skew-negative: calc(var(--skew) * -1);

      flex-grow: 1;

      @include media-breakpoint-up(xl) {
        --skew: 25deg;
        --navigation-bg: #{__get($theme-colors, 'action-1')};

        justify-content: end;
        flex-grow: 0;
      }

      li {
        a {
          --bs-link-color-rgb: #{$grey-800};

          @include media-breakpoint-up(xl) {
            --bs-link-color-rgb: #{$grey-800};
          }

          justify-content: left;
          height: min-content;
          white-space: nowrap;

          &:hover,
          &.active {
            --bs-link-color-rgb: #{__get($theme-colors, 'bodycolor')};
          }
        }
      }

      @include media-breakpoint-up(xl) {
        li:first-child a {
          padding-left: calc(var(--navigation-padding-x) * 1.5);
        }

        li:nth-last-child(1 of :not(.skew)) a {
          padding-right: calc(var(--navigation-padding-x) * 1.5);
        }
      }

      li:last-child {
        margin-top: auto;

        svg {
          justify-self: center;
        }

        a {
          --navigation-bg: #{$primary};
          --bs-link-color-rgb: #{$white};
          --bs-body-font-weight: 700;
          --focus-ring-outline-offset: 2px;

          @include media-breakpoint-up(xl) {
            --navigation-padding-x: #{$grid-gutter-width * 0.75};
            --skew: 25deg;

            &:hover {
              --navigation-bg: #{$red-800};
            }
          }

          @include media-breakpoint-down(xl) {
            font-size: px-to-rem(16px);
            justify-content: center;
            padding: $grid-gutter-width * 0.5 $grid-gutter-width;
          }
        }
      }
    }

    &__language {
      a {
        --bs-link-hover-color-rgb: #{__get($theme-colors, 'bodycolor')};
        --bs-link-color-rgb: #{__get($theme-colors, 'bodycolor')};
        --focus-ring-outline-offset: -2px;
        --padding-y: #{px-to-rem(10px)};
        --padding-x: #{$grid-gutter-width * 0.75};

        @include media-breakpoint-up(xl) {
          --padding-y: 0;
          --padding-x: #{$grid-gutter-width * 0.5};

          &.easy {
            --padding-y: #{$grid-gutter-width * 0.25};
          }
        }

        @include media-breakpoint-up(xxl) {
          &.easy {
            --padding-y: #{$grid-gutter-width * 0.5};
          }
        }

        padding: var(--padding-y) var(--padding-x);
        text-decoration: none;

        p {
          margin: 0;
          font-size: px-to-rem(18px);

          @include media-breakpoint-up(xl) {
            font-size: var(--bs-body-font-size);
            width: min-content;
          }

          @include media-breakpoint-up(xxl) {
            width: auto;
          }
        }

        &.default p {
          font-weight: 500;
        }

        &.easy p {
          font-weight: 600;
          line-height: 1.2;
        }

        &:hover {
          color: var(--bs-link-hover-color-rgb);
          text-decoration: underline;

          svg {
            &.show {
              display: none;
            }

            &.hidden {
              display: block;
            }
          }
        }

        svg {
          &.show {
            display: block;
          }

          &.hidden {
            display: none;
          }
        }
      }
    }
  }

  // main navigation
  &-main {
    --bs-body-font-weight: 700;
    --bs-dropdown-min-width: 100%;

    .navigation {
      &__list {
        &-second-level {
          width: 100%;
          @include media-breakpoint-up(xl) {
            width: fit-content;
          }
        }

        &-split {
          @include media-breakpoint-up(xl) {
            display: grid;
            grid-template-rows: repeat(4, min-content);
            grid-auto-columns: minmax(0, 1fr);
            grid-auto-flow: column;
          }
        }
      }
    }
  }

  // footer navigation
  &-footer {
    --bs-link-color-rgb: currentColor;
    --bs-body-font-weight: 700;
    --navigation-font-size: #{px-to-rem(16px)};

    @include media-breakpoint-up(ultrawide) {
      --navigation-font-size: #{px-to-rem(18px)};
    }

    ul {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
    }

    .navigation__item {
      & > div > .navigation__link {
        --navigation-font-size: #{px-to-rem(18px)};
      }

      svg {
        color: var(--brand-color);
      }
    }

    .navigation__link {
      justify-content: start;
    }

    &__meta {
      --bs-link-color-rgb: currentColor;
      --navigation-font-size: #{px-to-rem(14px)};
      --bs-body-font-size: #{px-to-rem(14px)};

      @include media-breakpoint-up(ultrawide) {
        --navigation-font-size: #{px-to-rem(16px)};
        --bs-body-font-size: #{px-to-rem(16px)};
      }

      a,
      span {
        text-decoration: none;
        font-size: var(--bs-body-font-size);
      }
    }
  }

  &-mobile {
    .navigation__item {
      .navigation__link {
      }
    }
  }

  &-breadcrumb {
    --bs-breadcrumb-divider: '|';
    --bs-breadcrumb-font-size: #{px-to-rem(16px)};

    @include media-breakpoint-up(xl) {
      --bs-breadcrumb-font-size: #{px-to-rem(18px)};
    }
  }
}
