.frame-type-theme_teaser_hero_home {
  h1 {
    --min-size: 44;
    --max-size: 84;
  }

  .btn {
    --bs-btn-font-size: #{px-to-rem(16px)};

    &-icon {
      --bs-btn-padding-x: 0;
      --bs-btn-padding-y: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    .btn {
      --bs-btn-font-size: #{px-to-rem(18px)};

      &-icon {
        --bs-btn-padding-y: 16px;
      }
    }
  }

  .container {
    max-width: none;

    @include media-breakpoint-up(md) {
      max-width: var(--container-max-width);
    }
  }
}
