.timeline {
  &-item {
    --timeline-item-columns: #{$grid-gutter-width * 0.25} minmax(auto, calc(100% - #{$grid-gutter-width * 0.75}));
    --timeline-item-rows: auto auto 1fr;
    --timeline-item-gap: #{px-to-rem(14px)};
    --timeline-item-content-column: 2;
    --timeline-item-content-row: 3;
    --timeline-item-year-column: 2;
    --timeline-item-year-row: 1/3;
    --timeline-item-year-justify-self: start;
    --timeline-item-year-line-left: calc(var(--timeline-item-gap) * -1);
    --timeline-item-year-line-right: unset;
    --timeline-item-year-margin: 0 0 0 #{px-to-rem(18px)};
    --timeline-line-column: 1;
    --timeline-line-row: 1/4;
    --timeline-dot-row: 1/3;
    --timeline-dot-dimension: #{px-to-rem(10px)};
    --text-wrap: balance;

    grid-template-columns: var(--timeline-item-columns);
    grid-template-rows: var(--timeline-item-rows);
    column-gap: var(--timeline-item-gap);

    @include media-breakpoint-up(md) {
      --timeline-item-gap: #{$grid-gutter-width * 1.75};
    }

    @include media-breakpoint-up(lg) {
      --timeline-item-columns: 1fr auto 1fr;
      --timeline-item-content-column: 1;
      --timeline-item-content-row: 1/4;
      --timeline-item-year-column: 3;
      --timeline-line-column: 2;
      --timeline-dot-dimension: #{px-to-rem(14px)};
      --timeline-item-gap: #{$grid-gutter-width * 1.5};

      &:nth-child(2n) {
        --timeline-item-content-column: 3;
        --timeline-item-year-column: 1;
        --timeline-item-year-justify-self: end;
        --timeline-item-year-line-left: unset;
        --timeline-item-year-line-right: calc(var(--timeline-item-gap) * -1);
        --timeline-item-year-margin: 0 #{px-to-rem(18px)} 0 0;
      }
    }

    @include media-breakpoint-up(xl) {
      --timeline-item-gap: #{$grid-gutter-width * 1.75};
    }

    @include media-breakpoint-up(ultrawide) {
      --timeline-item-gap: #{$grid-gutter-width * 2.25};
    }

    &__content {
      grid-column: var(--timeline-item-content-column);
      grid-row: var(--timeline-item-content-row);

      h3 {
        --min-size: 24;
      }
    }

    &__year {
      grid-column: var(--timeline-item-year-column);
      grid-row: var(--timeline-item-year-row);
      justify-self: var(--timeline-item-year-justify-self);
      position: relative;

      h2 {
        --max-size: 28;
        --min-size: 20;
        --skew: 25deg;
        --skew-negative: calc(var(--skew) * -1);
        --padding: #{px-to-rem(12px)} #{$grid-gutter-width * 0.75};

        width: fit-content;
        transform: skew(var(--skew-negative), 0);
        padding: var(--padding);
        margin: var(--timeline-item-year-margin);

        @include media-breakpoint-up(lg) {
          --padding: #{$grid-gutter-width * 0.5} #{$grid-gutter-width};
        }

        span {
          display: block;
          transform: skew(var(--skew), 0);
        }
      }

      &:before {
        content: '';
        position: absolute;
        height: 2px;
        width: calc(var(--timeline-item-gap) + 19px);
        top: calc(50% - 1px);
        left: var(--timeline-item-year-line-left);
        right: var(--timeline-item-year-line-right);
        background-color: __get($theme-colors, 'primary');
      }

      &:after {
        content: '';
        position: absolute;
        width: 2px;
        top: 0;
        background-color: __get($theme-colors, 'white');
      }
    }

    //line
    &:before {
      content: '';
      grid-column: var(--timeline-line-column);
      grid-row: var(--timeline-line-row);
      width: 2px;
      background-color: __get($theme-colors, 'action-4');
      height: 100%;
      justify-self: center;
    }

    //line styles for first item
    &:first-child {
      &:before {
        --timeline-line-row: 2/4;
      }
    }

    //dot
    &:after {
      content: '';
      grid-row: var(--timeline-dot-row);
      grid-column: var(--timeline-line-column);
      width: var(--timeline-dot-dimension);
      height: var(--timeline-dot-dimension);
      background-color: __get($theme-colors, 'primary');
      align-self: center;
    }
  }
}
