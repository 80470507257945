.bodytext {
  @include bodytext;

  *:not(h3, h4, .textmedia__heading) {
    &:has(+ h3, + h4) {
      margin-bottom: $grid-gutter-width * 2;

      @include media-breakpoint-up(xl) {
        margin-bottom: $grid-gutter-width * 2.5;
      }
    }
  }

  & > p {
    hyphens: auto;
  }
}
