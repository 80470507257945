.frame-type-uploads {
  h2,
  h3 {
    margin-bottom: $grid-gutter-width * 0.75;

    @include media-breakpoint-up(xl) {
      margin-bottom: $grid-gutter-width * 1.25;
    }
  }

  ul {
    --bs-body-font-size: #{px-to-rem(16px)};

    @include media-breakpoint-up(xl) {
      --bs-body-font-size: #{px-to-rem(18px)};
    }
  }

  .downloads {
    &__filename {
      font-size: px-to-rem(22px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(26px);
      }

      text-wrap: balance;
      word-break: break-all;
      line-height: 1.3;
    }

    &__filesize {
      font-size: px-to-rem(14px);

      @include media-breakpoint-up(xl) {
        font-size: px-to-rem(16px);
      }

      letter-spacing: 1.6px;
    }

    svg {
      height: 20px;
      width: 20px;

      @include media-breakpoint-up(xl) {
        height: 22px;
        width: 22px;
      }
    }
  }
}
