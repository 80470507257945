figure {
  margin-bottom: 0;

  &.news-detail__figure {
    .frame-type-news_newsdetail & {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
