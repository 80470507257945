@use 'sass:math';

.news_breaking_news {
  --font-size: 14px;
  --letter-spacing: 1.28px;

  @include media-breakpoint-up(md) {
    --font-size: 16px;
  }

  @include media-breakpoint-up(xl) {
    --font-size: 18px;
    --letter-spacing: 1.44px;
  }

  @include media-breakpoint-up(ultrawide) {
    --font-size: 20px;
    --letter-spacing: 1.6px;
  }

  .row > * {
    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .grid-content {
    grid-column: 1 / 4;

    @include media-breakpoint-up(xl) {
      grid-column: 2 / 3;
    }
  }

  &-title {
    font-size: var(--font-size);
    letter-spacing: var(--letter-spacing);
    font-weight: 900;
    line-height: 150%;
    padding-left: $grid-gutter-width * 0.5;
    white-space: nowrap;
    text-transform: uppercase;
    font-style: italic;
    position: relative;
    background-color: __get($theme-colors, 'primary');

    &:after {
      content: '';
      width: $grid-gutter-width * 0.75;
      height: 100%;
      position: absolute;
      left: 100%;
      top: 0;
      clip-path: polygon(0% 0%, 0 100%, 100% 0);
      background: __get($theme-colors, 'brand-red');
      transform: translateX(-1px);
    }

    @include media-breakpoint-up(ultrawide) {
      padding-left: calc(percentage(math.div(1, 12)) + $grid-gutter-width * 0.5);
    }
  }

  &-link {
    --line-clamp: 2;
    --max-height: 55px;
    --bs-btn-color: #{__get($theme-colors, 'white')};
    --bs-btn-bg: #{$grey-1100};
    --bs-btn-hover-color: #{__get($theme-colors, 'white')};
    --bs-btn-active-color: #{__get($theme-colors, 'white')};
    --bs-btn-hover-bg: #{$grey-1100};
    --bs-btn-active-bg: #{$grey-1100};
    --bs-btn-icon: #{url(inline-svg('arrow', __get($theme-colors, 'white')))};
    --bs-btn-icon-width: 27px;
    --bs-btn-icon-height: 18px;

    font-size: var(--font-size);
    font-weight: 700;
    line-height: 150%;
    padding-right: $grid-gutter-width * 0.5;
    overflow: hidden;
    max-height: var(--max-height);

    &:hover {
      --bs-btn-icon: #{url(inline-svg('arrow', __get($theme-colors, 'white')))};

      > span:before {
        transform: translateX(var(--bs-btn-icon-transition));
      }
    }

    &:before {
      content: attr(data-header) ':';
      font-style: italic;
      font-weight: 800;
      background: none;
      text-transform: uppercase;
    }

    @include media-breakpoint-down(md) {
      display: -webkit-box;
      -webkit-line-clamp: var(--line-clamp);
      -webkit-box-orient: vertical;
      text-align: left;
    }

    @include media-breakpoint-up(sm) {
      --line-clamp: 1;
      --max-height: 34px;
    }

    @include media-breakpoint-up(md) {
      --max-height: unset;

      &:before {
        content: none;
      }
    }

    &:after {
      content: none;
    }

    @include media-breakpoint-up(xl) {
      --bs-btn-icon-width: 30px;
      --bs-btn-icon-height: 20px;
    }

    @include media-breakpoint-up(ultrawide) {
      padding-right: calc(percentage(math.div(1, 12)) + $grid-gutter-width * 0.5);
    }

    > span {
      white-space: initial;
      overflow: hidden;
      text-overflow: ellipsis;

      &:before {
        content: '';
        display: inline-block;
        transition: transform 0.2s ease-in-out;
        background: var(--bs-btn-icon) no-repeat center right;
        width: var(--bs-btn-icon-width);
        height: var(--bs-btn-icon-height);
        flex: 0 0 var(--bs-btn-icon-width);
        vertical-align: text-bottom;
        margin: 0 $grid-gutter-width * 0.25;
      }

      @include media-breakpoint-up(md) {
        white-space: nowrap;
      }
    }
  }
}
