.frame-type-theme_teaser_logo {
  .h4 {
    --h4-font-size: 18px;

    @include media-breakpoint-up(xl) {
      --h4-font-size: 20px;
    }
  }

  .logo-bar {
    --logo-gap: #{$grid-gutter-width * 0.75};
    --grid-columns: 2;

    @include media-breakpoint-up(sm) {
      --grid-columns: 3;
    }

    @include media-breakpoint-up(md) {
      --grid-columns: 4;
    }

    @include media-breakpoint-up(lg) {
      --logo-gap: #{$grid-gutter-width * 0.75};
      --grid-columns: 6;
    }

    @include media-breakpoint-up(ultrawide) {
      --logo-gap: #{$grid-gutter-width};
    }

    display: grid;
    grid-template-columns: repeat(var(--grid-columns), 1fr);
    column-gap: var(--logo-gap);
    row-gap: $grid-gutter-width * 0.5;

    li {
      padding: $grid-gutter-width * 0.5;
    }

    &__logo {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
}
