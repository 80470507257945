@each $heading, $settings in $headings-font-settings {
  h#{$heading},
  .h#{$heading} {
    hyphens: auto;
    text-wrap: var(--text-wrap-h#{$heading}, var(--text-wrap));
    line-height: var(--headings-line-height, #{__get($settings, 'line-height')});

    @if (__get($settings, 'margin.xs')) {
      margin-bottom: var(--headings-margin, #{__get($settings, 'margin.xs')});
    }

    @if (__get($settings, 'margin.xl')) {
      @include media-breakpoint-up(xl) {
        --headings-margin: #{__get($settings, 'margin.xl')};
      }
    }

    --min-size: #{__get($settings, 'min')};
    --max-size: #{__get($settings, 'max')};

    --font-size: calc(
      /* Minimum size in pixels -> the starting point */
        var(--min-size, #{__get($settings, 'min')}) *
        1px +
        /* Diff between min and max -> how much should we add in total? */
        (
          (var(--max-size, #{__get($settings, 'max')}) - var(--min-size, #{__get($settings, 'min')})) *
            /* Container size minus starting point -> how far are we? */
            (100cqw - var(--container-min) * 1px) /
            /* Diff between min and max container width -> the range */
            (var(--container-max) - var(--container-min))
        )
    );

    --h#{$heading}-font-size: clamp(
      var(--min-size, #{__get($settings, 'min')}) * 1px,
      var(--font-size),
      var(--max-size, #{__get($settings, 'max')}) * 1px
    );
  }
}

h5,
.h5 {
  font-weight: 600;
}

.subheader {
  @include subheading;
}
