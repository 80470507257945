.stretched-link,
.slides__item-download a {
  &:hover {
    svg {
      @each $colorName, $colorValue in $theme-colors {
        &.text-#{$colorName} {
          --bs-#{$colorName}-rgb: var(--bs-link-hover-color-rgb);
        }
      }
    }
  }
}
