.frame-type-text {
  h2 {
    --headings-margin: #{$grid-gutter-width * 0.5};

    @include media-breakpoint-up(xl) {
      --headings-margin: #{$grid-gutter-width * 0.75};
    }
  }

  h3 {
    --min-size: 24;
  }
}
