.frame-type-theme_contact {
  h3 {
    --min-size: 22;
    --max-size: 26;
  }

  p,
  a {
    --bs-body-font-size: #{px-to-rem(18px)};

    @include media-breakpoint-up(xl) {
      --bs-body-font-size: #{px-to-rem(20px)};
    }
  }

  a {
    font-size: var(--bs-body-font-size);
  }

  svg {
    @include media-breakpoint-up(xl) {
      width: 48px;
      height: 48px;
    }
  }
}
