#supi__overlay {
  --supi-overlay-padding: 0;
  --supi-button-padding: #{$grid-gutter-width * 0.5} #{$grid-gutter-width};

  @include media-breakpoint-up(md) {
    place-content: end center;
  }

  @include media-breakpoint-up(xl) {
    place-content: center;
  }
}

#supi__banner {
  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: none;
  }

  @include media-breakpoint-up(xl) {
    width: 75vw;
    max-width: 870px;
    min-width: auto;
  }

  --supi-headings-font-family: #{$font-family-sans-serif};
  --supi-headings-font-weight: #{$font-weight-bold};
  --supi-headings-font-size: #{px-to-rem(26px)};
  --supi-banner-padding: #{$grid-gutter-width} #{$grid-gutter-width * 0.75} #{$grid-gutter-width * 0.75} #{$grid-gutter-width *
    0.75};

  @include media-breakpoint-up(md) {
    --supi-banner-padding: #{$grid-gutter-width * 1.25};
  }

  @include media-breakpoint-up(xl) {
    --supi-headings-font-size: #{px-to-rem(30px)};
  }

  @include media-breakpoint-up(ultrawide) {
    --supi-headings-font-size: #{px-to-rem(34px)};
  }

  --supi-base-font-family: var(--supi-headings-font-family);
  --bs-body-font-size: #{px-to-rem(18px)};

  --supi-table-background: var(--bs-action-1);
  --supi-table-border: var(--bs-action-2);

  .tx-supi__pane-visible {
    gap: $grid-gutter-width;
  }

  button {
    font-weight: $font-weight-bold;
    font-size: px-to-rem(16px);
  }

  #supi__label {
    display: flex;
    gap: $grid-gutter-width * 0.5;
    align-items: center;
    margin-bottom: $grid-gutter-width * 0.5;
    text-wrap: balance;

    &:before {
      content: '';
      display: block;
      flex: 0 0 $grid-gutter-width;
      width: $grid-gutter-width;
      aspect-ratio: 1/1;
      background: url(inline-svg('ico-cookie', black)) no-repeat center;
    }
  }
}

.frame-type-tx_supi_youtube {
  h3 {
    margin-bottom: $grid-gutter-width * 0.75;

    @include media-breakpoint-up(xl) {
      margin-bottom: $grid-gutter-width * 1.25;
    }
  }

  .tx-supi {
    &__youtube {
      &-image {
        --supi-yt-overlay-background: transparent;
        grid-template-rows: 1fr auto;
        display: grid;

        picture {
          grid-column: 1;
          grid-row: 1;
        }
      }

      &-text {
        grid-column: 1;
        grid-row: 2;
        backdrop-filter: blur(4px);

        @include media-breakpoint-up(md) {
          grid-row: 1;
        }
      }
    }
  }
}
