.btn {
  @include media-breakpoint-up(xl) {
    --bs-btn-font-size: #{px-to-rem(18px)};
  }

  &:focus-visible {
    background-color: var(--bs-btn-bg);
  }

  &:disabled {
    --bs-btn-disabled-bg: #{$grey-300};
    --bs-btn-disabled-border-color: #{$grey-300};
    --bs-btn-disabled-color: #{$white};
  }

  &-italic:not(:disabled) {
    background: transparent;
    border: transparent;
    display: grid;

    & > span,
    &:before {
      position: relative;
      grid-column: 1;
      grid-row: 1;
    }

    & > span {
      z-index: 1;
    }

    &:before {
      content: '';
      background: var(--bs-btn-bg);
      z-index: 0;
      width: calc(100% + 64px);
      height: calc(100% + 32px);
      transform: translate(-32px, -16px) skewX(-15deg);
    }

    &:hover:before {
      background: var(--bs-btn-hover-bg);
    }

    &:active {
      background: transparent;
    }

    &:focus-visible {
      outline: none !important;

      &:after {
        content: '';
        border: 2px solid var(--bs-btn-bg);
        z-index: 0;
        width: calc(100% + 72px);
        height: calc(100% + 60px);
        -webkit-transform: translate(-34px, -18px) skewX(-15deg);
        -ms-transform: translate(-34px, -18px) skewX(-15deg);
        transform: translate(-36px, -44px) skewX(-15deg);
      }
    }
  }

  &-link {
    --bs-btn-font-weight: #{$btn-font-weight};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;
    }
  }

  &-icon {
    @extend .btn-link;
    --bs-btn-font-size: #{px-to-rem(20px)};

    display: inline-flex;
    align-items: center;
    gap: var(--bs-btn-icon-gap);

    @include media-breakpoint-up(xl) {
      --bs-btn-font-size: #{px-to-rem(24px)};
    }

    @include media-breakpoint-up(ultrawide) {
      --bs-btn-font-size: #{px-to-rem(26px)};
    }

    &:hover {
      text-decoration: none;

      &:before {
        @include motion-safe {
          transform: translateX(var(--bs-btn-icon-transition));
        }
      }
    }

    &:before {
      content: '';
      background: var(--bs-btn-icon) no-repeat center right;
      width: var(--bs-btn-icon-width);
      height: var(--bs-btn-icon-height);
      flex: 0 0 var(--bs-btn-icon-width);
      align-self: var(--bs-btn-icon-align, center);

      @include motion-safe {
        transition: $btn-icon-transition;
      }
    }

    &-right {
    }

    &-left {
      transform: rotate(180deg);
    }

    &-arrow {
      --bs-btn-icon: #{url(inline-svg('arrow'))};
      --bs-btn-font-size: #{px-to-rem(16px)};

      @include media-breakpoint-up(xl) {
        --bs-btn-font-size: #{px-to-rem(18px)};
      }

      &:hover {
        --bs-btn-icon: #{url(inline-svg('arrow', shade-color($primary, $btn-hover-bg-shade-amount)))};
      }

      &-big {
        --bs-btn-icon-width: 36px;
        --bs-btn-icon-height: 24px;
        --bs-btn-font-size: #{px-to-rem(20px)};
        --bs-btn-icon-gap: #{$grid-gutter-width * 0.75};
        --bs-btn-line-height: 1.2;
        --bs-btn-icon-align: flex-start;

        @include media-breakpoint-up(xl) {
          --bs-btn-icon-width: 45px;
          --bs-btn-icon-height: 30px;
          --bs-btn-font-size: #{px-to-rem(24px)};
          --bs-btn-icon-gap: #{$grid-gutter-width};
        }

        @include media-breakpoint-up(ultrawide) {
          --bs-btn-font-size: #{px-to-rem(26px)};
          --bs-btn-icon-width: 54px;
          --bs-btn-icon-height: 36px;
        }

        &:disabled {
          --bs-btn-icon: #{url(inline-svg('arrow', $grey-300))};
          --bs-btn-disabled-bg: transparent;
          --bs-btn-disabled-border-color: transparent;
        }
      }

      &-end {
        &:before {
          order: 1;
        }
      }
    }
  }

  &-small {
    --bs-btn-padding-y: #{$grid-gutter-width * 0.125};
    --bs-btn-padding-x: 0;
  }
}

[class*='btn-outline-'] {
  --bs-btn-hover-bg: #{$primary};
  --bs-btn-hover-color: #{$white};

  &:disabled {
    --bs-btn-disabled-color: #{$grey-300};
    --bs-btn-disabled-bg: transparent;
  }

  &:focus-visible {
    --bs-btn-bg: var(--bs-btn-hover-bg);
  }

  @each $colorName, $colorValue in $theme-colors {
    [class*='-#{$colorName}'] & {
      --bs-btn-hover-bg: #{$white};
      --bs-btn-hover-color: #{$colorValue};
    }

    [class*='bg-brand'] & {
      --bs-btn-hover-bg: #{$white};
      --bs-btn-hover-color: var(--brand-color);
    }
  }
}

[class^='btn btn-'][class$='-white'] {
  --bs-btn-hover-bg: #{white};
  --bs-btn-hover-color: #{$primary};

  &:disabled {
    --bs-btn-disabled-color: #{$grey-300};
    --bs-btn-disabled-bg: transparent;
  }

  &:focus-visible {
    --bs-btn-bg: var(--bs-btn-hover-bg);
  }
}
