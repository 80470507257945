.event {
  &-calendar {
    h3 {
      &.heading-lines {
        --min-size: 18;
        --max-size: 20;
      }
    }

    &-selector {
      &__list-item {
        h2 {
          --min-size: 26;
          --max-size: 30;

          a.active {
            position: relative;

            &:before {
              position: absolute;
              content: '';
              left: 0;
              width: 100%;
              height: 5px;
              bottom: -16px;
              background-color: __get($theme-colors, 'primary');
            }
          }
        }
      }
    }

    &__list {
      --grid-columns: 1;
      grid-template-columns: repeat(var(--grid-columns), 1fr);

      @include media-breakpoint-up(md) {
        --grid-columns: 2;
        grid-auto-rows: 1fr;
      }

      @include media-breakpoint-up(lg) {
        --grid-columns: 3;
      }

      @include media-breakpoint-up(ultrawide) {
        --grid-columns: 4;
      }

      &-item {
        time {
          font-size: px-to-rem(16px);

          @include media-breakpoint-up(xl) {
            font-size: px-to-rem(18px);
          }
        }
        h4 {
          --min-size: 22;
          --max-size: 26;
        }

        a {
          line-height: 1.2;

          @include media-breakpoint-up(xl) {
            font-size: #{px-to-rem(18px)};
          }

          svg {
            --icon-size: 20px;

            @include media-breakpoint-up(xl) {
              --icon-size: 22px;
            }

            height: var(--icon-size);
            width: var(--icon-size);
            flex: 0 var(--icon-size);
          }
        }
      }
    }
  }
}
