.heading-lines {
  display: grid;
  grid-template-columns: minmax(#{$grid-gutter-width * 0.5}, 1fr) auto minmax(#{$grid-gutter-width * 0.5}, 1fr);
  grid-template-rows: auto;
  --min-size: 18;
  --max-size: 20;
  line-height: 1.2;

  span {
    grid-row: 1 / -1;
    grid-column: 2;
    text-align: center;
    text-wrap: balance;
  }

  &:before,
  &:after {
    content: '';
    height: 1px;
    background: __get($theme-colors, 'action-4');
    align-self: center;
    grid-row: 1 / -1;
    grid-column: 1;
  }

  &:after {
    grid-column: 3;
  }
}
