@use 'sass:math';

.frame-type-news_pi1,
.frame-type-news_newsdetail {
  .news-list {
    --news-list-column-count: 1;
    --news-list-row-gap: #{$grid-gutter-width};
    --news-list-column-gap: #{$grid-gutter-width};

    @include media-breakpoint-up(md) {
      --news-list-column-count: 2;
      --news-list-column-gap: #{$grid-gutter-width * 0.75};
    }

    @include media-breakpoint-up(xl) {
      --news-list-column-count: 3;
      --news-list-row-gap: #{$grid-gutter-width * 1.25};
      --news-list-column-gap: #{$grid-gutter-width};
    }

    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(var(--news-list-column-count), 1fr);
    column-gap: var(--news-list-column-gap);
    row-gap: var(--news-list-row-gap);

    &__item {
      display: flex;
      flex-direction: column;

      h2 {
        --min-size: 22;
        --max-size: 26;
      }

      time,
      a {
        @include media-breakpoint-up(xl) {
          font-size: px-to-rem(18px);
        }
      }

      &-first {
        grid-column: 1/ -1;

        h2 {
          --min-size: 26;
          --max-size: 34;
        }

        figure {
          flex: 0 0 percentage(math.div(6, 12));
        }
      }
    }
  }

  .news-detail {
    &__date {
      --bs-body-font-size: #{px-to-rem(18px)};
      font-size: var(--bs-body-font-size);

      @include media-breakpoint-up(xl) {
        --bs-body-font-size: #{px-to-rem(22px)};

        svg {
          width: $grid-gutter-width;
          height: $grid-gutter-width;
        }
      }
    }

    &__related-links {
      ul {
        --bs-body-font-size: #{px-to-rem(16px)};

        @include media-breakpoint-up(xl) {
          --bs-body-font-size: #{px-to-rem(18px)};
        }
      }

      .links {
        &-item {
          --link-inline-svg: #{url(inline-svg('ico-link', __get($theme-colors, 'primary')))};

          &:has(a[href^='tel']) {
            --link-inline-svg: #{url(inline-svg('ico-phone', __get($theme-colors, 'primary')))};
          }

          &:has(a[href^='mailto']) {
            --link-inline-svg: #{url(inline-svg('ico-envelope', __get($theme-colors, 'primary')))};
          }

          &:has(a[href^='http']) {
            --link-inline-svg: #{url(inline-svg('ico-arrow-up-right-from-square'))};
          }

          .arrow svg {
            --width: 30px;
            --height: 20px;

            width: var(--width);
            height: var(--height);

            @include media-breakpoint-up(xl) {
              --width: 36px;
              --height: 24px;
            }
          }
        }

        &__title {
          &:before {
            --dimension: #{$grid-gutter-width * 1.25};

            content: var(--link-inline-svg);
            flex-shrink: 0;
            width: var(--dimension);
            height: var(--dimension);
          }

          @include media-breakpoint-up(xl) {
            font-size: px-to-rem(26px);
          }

          b {
            text-wrap: wrap;
            word-break: break-word;
            font-size: px-to-rem(22px);
            line-height: 1.2;

            @include media-breakpoint-up(xl) {
              font-size: px-to-rem(26px);
              line-height: 1.3;
            }
          }
        }

        &__description {
          font-size: px-to-rem(16px);
        }
      }
    }
  }

  &:has(.news-list.frame):has(.pagination) {
    .news-list.frame {
      margin-bottom: $grid-gutter-width * 2;

      @include media-breakpoint-up(xl) {
        margin-bottom: $grid-gutter-width * 2.25;
      }
    }
  }

  &:has(.news_breaking_news) {
    --full-width-grid-color-1: #{__get($theme-colors, 'brand-red')};
    --full-width-grid-color-2: #{$grey-1100};
  }
}
