.list {
  &-links {
    --list-links-gap: #{$grid-gutter-width};

    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    gap: var(--list-links-gap);

    flex-wrap: wrap;

    & > li {
      flex: 1 0 calc(50% - var(--list-links-gap));

      .btn {
        hyphens: auto;
        text-align: start;
        white-space: normal;
        text-wrap: balance;
      }
    }
  }
}
