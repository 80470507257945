#header {
  position: sticky;
  top: 0;
  z-index: 1002;

  .skew-bottom:before {
    --skew-clip-path: polygon(0 0, 0 100%, calc(100% + #{$grid-gutter-width}) 0);
    --skew-transform: -1px;
  }

  .header-wrapper {
    position: relative;

    @include media-breakpoint-up(xl) {
      position: unset;
    }
  }
}
