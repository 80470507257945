.skip-nav {
  position: absolute;
  top: 3px;
  left: 3px;

  &__link {
    display: block;
    position: absolute;
    top: -200px;
    z-index: 1000;
    padding: $grid-gutter-width * 0.5;
    background-color: __get($theme-colors, 'primary');

    &:focus-visible {
      top: 0;
    }
  }
}
