.collapse {
  &-wrapper .row > div {
    @include media-breakpoint-down(xl) {
      padding: 0;
    }
  }

  &-inner {
    --overflow: scroll;

    height: auto;
    overflow: var(--overflow);
    padding-top: $grid-gutter-width * 0.75;
    scrollbar-width: none;

    @include media-breakpoint-down(md) {
      height: var(--max-navigation-height);
    }

    @include media-breakpoint-up(xl) {
      --overflow: auto;

      position: relative;
      padding-top: px-to-rem(84px);

      @include motion-safe() {
        transition: height 0.4s;
      }
    }
  }

  &__first-level {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1000;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 100%;
    overflow: hidden;

    @include media-breakpoint-up(xl) {
      top: 85%;
      left: 0;

      transition: none;

      @include motion-safe {
        transition: top 0.5s ease-in-out;
      }
    }

    &.show {
      visibility: visible;
      top: 0;
      left: 0;
      opacity: 1;
      height: 100%;
      min-height: 100%;
      overflow: auto;
      background-color: __get($theme-colors, 'white');

      @include media-breakpoint-up(xl) {
        top: 100%;
        height: auto;
        background-color: unset;
        overflow: visible;
      }
    }

    .navigation__list-wrapper:not(:first-of-type) {
      @include media-breakpoint-up(xl) {
        border-left: 1px solid $grey-200;
        margin: 0 auto;
      }
    }

    &:has(.list-header) {
      .navigation {
        &__list {
          &-no-header {
            @include media-breakpoint-up(xl) {
              padding-top: px-to-rem(21px);
            }
          }
        }
      }
    }
  }

  &__second-level {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1001;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 100%;
    overflow: hidden;

    @include motion-safe {
      transition: all 0.2s ease-in-out;
    }

    @include media-breakpoint-up(xl) {
      left: 15%;
    }

    &.show {
      visibility: visible;
      opacity: 1;
      left: 0;
      height: 100%;
      max-height: var(--max-navigation-height);
      overflow: visible;
    }
  }

  .list {
    &-header {
      text-transform: uppercase;
      font-size: px-to-rem(14px);
      color: $grey-700;
      font-weight: 700;
      letter-spacing: 0.7px;
    }
  }
}
