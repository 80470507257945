@use 'sass:math';

.skew {
  @each $name, $color in $theme-colors {
    &-#{$name} {
      --skew-background: #{$color};
      --skew-color: #{color-contrast($color)};
    }

    [class*='#{$name}'] + & {
      --skew-overlay-background: #fff;
    }
  }

  --grid-template-columns: 1fr;
  --skew-column: 1 / -1;
  --content-column: 1 / -1;

  grid-template-rows: var(--grid-template-rows);
  grid-template-columns: var(--grid-template-columns);
  background: var(--skew-background);
  display: grid;

  &-xl-show {
    display: unset;

    @include media-breakpoint-up(xl) {
      display: grid;
    }
  }

  &-xl-hide {
    @include media-breakpoint-up(xl) {
      display: block;
      --skew-background: transparent;

      &:before {
        content: none !important;
      }
    }
  }

  &-top {
    --grid-template-rows: var(--skew-height) 1fr;
    --skew-row: 1;
    --content-row: 2;
  }

  &-right {
    --grid-template-rows: 1fr;
    --grid-template-columns: 1fr 22px;
    --skew-row: 1;
    --skew-column: 2;
    --content-row: 1;
    --content-column: 1;

    &:before {
      --skew-clip-path: polygon(0% 0%, 0 100%, 100% 0);
    }
  }

  &-bottom {
    --grid-template-rows: 1fr var(--skew-height, #{$grid-gutter-width});
    --skew-row: 2;
    --content-row: 1;
    overflow-y: hidden;

    &.skew-multi-content {
      --grid-template-rows: auto auto var(--skew-height, #{$grid-gutter-width});
      --skew-row: 3;
    }

    &:before {
      --skew-clip-path: polygon(100% 100%, 0 100%, calc(100% + #{$grid-gutter-width}) 0);
      --skew-transform: 1px;
    }
  }

  @include motion-safe {
    transition: all 0.2s ease-in-out;
  }

  &:before {
    content: '';
    background: var(--skew-overlay-background);
    grid-row: var(--skew-row);
    grid-column: var(--skew-column);
    clip-path: var(--skew-clip-path);
    transform: translateY(var(--skew-transform));
  }

  &-flag {
    .frame-type-theme_cta & {
      > div {
        grid-column: 1/-1;
        grid-row: 1/-1;
      }
    }

    &:after {
      content: '';
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='0 0 136 48' fill='none' xmlns='http://www.w3.org/2000/svg' id='logo-red-flag'%3E%3Cpath d='M13.9961 28.6602C13.9961 28.6602 27.4546 3.10735 54.4731 4.1211C77.365 4.97986 79.4911 14.6972 97.0577 15.2795C120.797 13.9058 135.998 0.378195 135.998 0.378195C135.998 0.378195 119.577 26.8313 91.993 25.884C67.5279 25.0435 69.9257 15.2566 47.596 14.7582C29.6131 14.3568 13.9961 28.6602 13.9961 28.6602Z' fill='currentColor'/%3E%3Cpath d='M0.214844 47.0508C0.214844 47.0508 13.673 21.498 40.6918 22.5117C63.5838 23.3705 65.7099 33.0874 83.276 33.6701C107.016 32.2964 122.217 18.7688 122.217 18.7688C122.217 18.7688 105.796 45.2219 78.2118 44.2742C53.7467 43.4341 56.1445 33.6472 33.8143 33.1488C15.8315 32.7474 0.214844 47.0508 0.214844 47.0508Z' fill='currentColor'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: center;
      background-size: contain;
      pointer-events: none;

      @include media-breakpoint-up(md) {
        background-position-y: 10px;
        background-size: cover;
      }

      @include media-breakpoint-up(xl) {
        background-size: percentage(math.div(7, 12));
        background-position-x: right;
      }

      @include media-breakpoint-up(ultrawide) {
        background-size: percentage(math.div(6, 12));
        background-position-y: -10px;
      }

      grid-row: 1 / -1;
      grid-column: 1 / -1;
      width: 100%;
      max-width: var(--container-max-width);
      height: 100%;
      justify-self: center;
      opacity: 0.05;
    }
  }

  & > :first-of-type {
    position: relative;
    z-index: 1;
    grid-row: var(--content-row);
    grid-column: var(--content-column);
    color: var(--skew-color);
  }

  &-full-height {
    --grid-template-rows: var(--skew-height) 1fr 1fr;

    *:first-child {
      --content-row: 1/2;
    }
    *:nth-child(2) {
      --content-row: 3;
    }
  }

  &-img {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include media-breakpoint-up(md) {
      display: block;
    }

    svg,
    &:after {
      grid-row: 1;
      grid-column: 1;
    }

    svg {
      z-index: 1;
    }

    &:after {
      @include media-breakpoint-up(md) {
        display: none;
      }

      content: '';
      background: var(--full-width-grid-color-1);
      margin-left: calc(var(--bs-gutter-x) * -0.5);
      margin-right: calc(var(--bs-gutter-x) * -0.5);
      clip-path: polygon(0 10%, 0% 100%, 100% 100%);
    }
  }

  &-shadow {
    filter: drop-shadow(0px 40px 16px rgba(0, 0, 0, 0.08));
  }
}
