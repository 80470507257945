#nav-breadcrumb {
  background-color: var(--breadcrumb-bg);

  ul {
    row-gap: $grid-gutter-width * 0.25;
    flex-wrap: nowrap;
    overflow: hidden;

    li {
      --bs-body-font-weight: 400;
      --bs-breadcrumb-divider-color: #{__get($theme-colors, 'bodycolor')};
      font-weight: var(--bs-body-font-weight);
      z-index: 1;
      flex: 0 0 auto;

      &.active {
        white-space: nowrap;
        overflow: hidden;
        flex-shrink: 1;
      }

      &:not(:first-child):not(:last-child) {
        @include media-breakpoint-between(xs, md) {
          @include visually-hidden-focusable;
        }
      }

      span {
        --bs-breadcrumb-item-active-color: #{__get($theme-colors, 'bodycolor')};
        color: var(--bs-breadcrumb-item-active-color);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }

      a {
        text-decoration: none;

        &:hover {
          --bs-link-hover-color-rgb: #{var(--bs-primary-rgb)};
          text-decoration: underline;
        }
      }
    }
  }

  &:has(+ main > .frame-type-theme_page_header) {
    li {
      text-shadow: 0 0 10px rgba(var(--bs-action-1-rgb, 0.8));
    }
  }

  &:has(+ main > .frame-type-solr_pi_results > .bg-action-1),
  &:has(+ main > .frame-type-news_pi1 > .bg-action-1),
  &:has(+ main > .pageheader) {
    // @TODO
    // this one is also set at the moment in the fluid files with inlining css
    // due to browser compatibility, we might remove this later this year, when
    // firefox is implementing the has selector
    --breadcrumb-bg: var(--bs-action-1);
  }

  & + main > .frame-type-news_pi1:has(.bg-action-1) {
    margin-top: 0;
  }
}
