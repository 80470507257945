.logo {
  --bs-body-font-size: #{px-to-rem(20px)};
  --logo-w: 89px;
  --logo-h: 44px;

  @include media-breakpoint-up(xl) {
    --logo-w: 164px;
    --logo-h: 105px;
  }

  @include media-breakpoint-up(ultrawide) {
    --bs-body-font-size: #{px-to-rem(24px)};
  }

  svg {
    width: var(--logo-w);
    height: var(--logo-h);
  }

  .footer-navigation & {
    svg {
      width: 143px;
      height: auto;

      @include media-breakpoint-up(xl) {
        width: auto;
      }
    }

    @include media-breakpoint-up(md) {
      flex: 0 1 190px;
    }

    @include media-breakpoint-up(xl) {
      flex: 0 auto;
    }
  }

  &-claim {
    hyphens: auto;
    font-size: var(--bs-body-font-size);
    font-weight: 700;
    line-height: 1.2;
  }
}
