.dialog {
  --max-width: calc(100dvw - #{$grid-gutter-width * 0.5});

  max-width: var(--max-width);

  &[open] {
    &::backdrop {
      backdrop-filter: blur(2px);
    }
  }

  @include media-breakpoint-up(sm) {
    --max-width: 500px;
  }

  @include media-breakpoint-up(md) {
    --max-width: 700px;
  }

  @include media-breakpoint-up(lg) {
    --max-width: 800px;
  }

  @include media-breakpoint-up(xl) {
    --max-width: 1140px;
  }

  .btn-outline-primary {
    --bs-btn-hover-bg: #{__get($theme-colors, 'primary')};
    --bs-btn-hover-color: #{__get($theme-colors, 'white')};
  }

  &-content {
    --shadow-opacity: 0.14;
    --shadow-blur: #{$grid-gutter-width * 1.75};
    --shadow-bottom: #{$grid-gutter-width * 0.75};
  }
}
