@mixin focus-ring-style {
  outline: __get($theme-colors, 'primary') solid 2px;
  box-shadow: none;
  outline-offset: var(--focus-ring-outline-offset, 2px);
  z-index: 1002;
  position: relative;
}

@mixin focus-ring {
  &:focus-visible {
    @include focus-ring-style;
  }

  @supports not selector(:focus) {
    &:focus {
      @include focus-ring-style;
    }
  }
}
