.teasertext {
  font-weight: $font-weight-bold;
  line-height: 1.5;
  font-size: px-to-rem(24px);

  @include media-breakpoint-up(xl) {
    font-size: px-to-rem(30px);
  }

  @include media-breakpoint-up(ultrawide) {
    font-size: px-to-rem(34px);
  }
}
