.frame {
  //background: var(--brand-color);
  //color: var(--brand-font-color);

  #page-title + & {
    margin-top: 0;
  }

  &:not(.full-width-grid) {
    margin-top: $grid-gutter-width * 2.5;
    margin-bottom: $grid-gutter-width * 2.5;

    @include media-breakpoint-up(xl) {
      margin-top: $grid-gutter-width * 4;
      margin-bottom: $grid-gutter-width * 4;
    }

    @include media-breakpoint-up(ultrawide) {
      margin-top: $grid-gutter-width * 4.5;
      margin-bottom: $grid-gutter-width * 4.5;
    }
  }

  &-type-persons_regions:not(.full-width-grid),
  &-type-persons_responsibilities:not(.full-width-grid),
  &-type-persons_selected:not(.full-width-grid) {
    margin-top: $grid-gutter-width * 1.75;
    &:not(:last-child) {
      margin-bottom: $grid-gutter-width * 1.75;
    }

    @include media-breakpoint-up(xl) {
      margin-top: $grid-gutter-width * 2;
      &:not(:last-child) {
        margin-bottom: $grid-gutter-width * 2;
      }
    }

    @include media-breakpoint-up(ultrawide) {
      margin-top: $grid-gutter-width * 2.5;
      &:not(:last-child) {
        margin-bottom: $grid-gutter-width * 2.5;
      }
    }
  }
}
