@use 'sass:math';

.frame-type-image {
  --image-gap: #{$grid-gutter-width * 0.75};
  --image-size-16by10: 100%;
  --image-size-1by1: 100%;
  --image-size-3by2: 100%;

  @include media-breakpoint-up(md) {
    --image-size-1by1: calc(#{percentage(math.div(5, 12))} - #{$grid-gutter-width * 0.375});
    --image-size-3by2: calc(#{percentage(math.div(7, 12))} - #{$grid-gutter-width * 0.375});
  }

  &__row {
    flex: 1 0 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--image-gap);

    figure {
      flex: 1 0 100%;

      img {
        width: 100%;
      }
    }

    @include media-breakpoint-up(md) {
      display: grid;
      grid-template-columns: var(--image-size-1by1) var(--image-size-3by2);
      grid-template-rows: 1fr auto;
      column-gap: var(--image-gap);
      row-gap: unset;

      figure {
        grid-row: 1 / span 2;
        display: grid;
        grid-template-rows: subgrid;

        picture,
        img {
          height: 100%;
          object-fit: cover;
          background: $grey-200;
        }
      }

      &:nth-child(even) {
        grid-template-columns: var(--image-size-3by2) var(--image-size-1by1);

        figure {
          &:first-child {
            order: 1;
          }

          &:last-child {
            order: 0;
          }
        }
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: var(--image-gap);

    & > figure {
      flex: 0 0 var(--image-size, var(--image-size-16by10));
      width: var(--image-size, var(--image-size-16by10));

      picture,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__1by1 {
    --image-size: var(--image-size-1by1);
  }

  &__3by2 {
    --image-size: var(--image-size-3by2);
  }

  &__16by10 {
    --image-size: var(--image-size-16by10);
  }
}
