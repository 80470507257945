@mixin subheading() {
  text-transform: uppercase;
  --letter-spacing: 1.4px;
  letter-spacing: var(--letter-spacing);

  @include media-breakpoint-up(xl) {
    --letter-spacing: 1.8px;
  }

  color: $primary;
  margin-bottom: $grid-gutter-width * 0.5;

  --container-max: 1200;
  --min-size: 14;
  --max-size: 16;
}
