@use 'sass:math';
@use 'sass:map';

// load all variables
@import 'breakpoints';
@import 'container';
@import 'aspect-ratios';

// Colors
$enable-dark-mode: false;

$white: #fff;
$orange: #df7b00;
$bodycolor: #282828;
$black: #000;

$grey-100: #f7f7f7;
$grey-200: #efefef;
$grey-300: #e1e1e1;
$grey-400: #b8b8b8;
$grey-500: #a3a3a3;
$grey-600: #8f8f8f;
$grey-700: #767676;
$grey-800: #666666;
$grey-900: #525252;
$grey-1000: #3d3d3d;
$grey-1100: #272727;

$green: #3fa435;
$green-100: #f1fff0;
$green-200: #c7ffc1;
$green-300: #97f68e;
$green-400: #7df471;
$green-500: #71eb65;
$green-600: #64da58;
$green-700: #56c64b;
$green-800: #3fa435;
$green-900: #2b8223;
$green-1000: #1b6014;

$blue: #0477bd;
$blue-100: #f4fbff;
$blue-200: #c6e9ff;
$blue-300: #a1dbff;
$blue-400: #7ccdff;
$blue-500: #58c0ff;
$blue-600: #33b2ff;
$blue-700: #1a99e6;
$blue-800: #0477bd;
$blue-900: #005c94;
$blue-1000: #00436b;

$red: #e30513;
$red-100: #fff2f3;
$red-200: #ffcdd0;
$red-300: #ff969c;
$red-400: #ff6d76;
$red-500: #ff4450;
$red-600: #ff1b29;
$red-700: #e30513;
$red-800: #cd0411;
$red-900: #ab040f;
$red-1000: #690007;

$primary: $red;
$secondary: $green;
$info: $blue;
$warning: $orange;
$danger: $red-1000;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'bodycolor': $bodycolor,
  'brand-red': $red,
  'brand-green': $green,
  'brand-blue': $blue,
  'brand-orange': $orange,
  'white': $white,
  'black': $black,
  'action-1': $grey-100,
  'action-2': $green-900,
  'action-3': $grey-300,
  'action-4': $grey-200,
  'action-5': $grey-700,
  'action-6': $red-800,
);

// Spacers
$enable-negative-margins: true;
$grid-gutter-width: 2rem;

$spacers: (
  0: 0,
  og: $grid-gutter-width * 0.125,
  qg: $grid-gutter-width * 0.25,
  qog: $grid-gutter-width * 0.375,
  hg: $grid-gutter-width * 0.5,
  tq: $grid-gutter-width * 0.75,
  fg: $grid-gutter-width,
  1qg: $grid-gutter-width * 1.25,
  1h: $grid-gutter-width * 1.5,
  1tq: $grid-gutter-width * 1.75,
  2g: $grid-gutter-width * 2,
  2qg: $grid-gutter-width * 2.25,
  2h: $grid-gutter-width * 2.5,
  2tq: $grid-gutter-width * 2.75,
  3g: $grid-gutter-width * 3,
  3qg: $grid-gutter-width * 3.25,
  3h: $grid-gutter-width * 3.5,
  3tq: $grid-gutter-width * 3.75,
  4g: $grid-gutter-width * 4,
  4h: $grid-gutter-width * 4.5,
  5g: $grid-gutter-width * 5,
  6g: $grid-gutter-width * 6,
);

// fonts
$enable-rfs: false;

$font-family-sans-serif: 'Figtree', sans-serif;

$headings-font-weight: 700;

// font-sizes
$h1-font-size: var(--h1-font-size);
$h2-font-size: var(--h2-font-size);
$h3-font-size: var(--h3-font-size);
$h4-font-size: var(--h4-font-size);
$h5-font-size: var(--h5-font-size);

$headings-font-settings: (
  1: (
    min: 36,
    max: 72,
    line-height: 1.2,
  ),
  2: (
    min: 30,
    max: 44,
    line-height: 1.2,
    margin: (
      xs: $grid-gutter-width * 0.75,
      xl: $grid-gutter-width * 1.25,
    ),
  ),
  3: (
    min: 26,
    max: 34,
    line-height: 1.3,
    margin: (
      xs: $grid-gutter-width * 0.5,
    ),
  ),
  4: (
    min: 20,
    max: 24,
    line-height: 1.2,
    margin: (
      xs: $grid-gutter-width * 0.5,
    ),
  ),
  5: (
    min: 16,
    max: 18,
    line-height: 1.2,
  ),
);

// buttons
$btn-padding-y: 16px;
$btn-padding-x: 32px;
$btn-font-size: 16px;
$btn-font-weight: 700;
$btn-white-space: nowrap;
$btn-line-height: 1;
$btn-box-shadow: none;
$btn-border-radius: 0;
$btn-border-width: 2px;

@import 'svg';

// inline icons
$inline-icons: map.merge(
  (
    'arrow':
      "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg viewBox='0 0 36 24' fill='none' xmlns='http://www.w3.org/2000/svg' id='ico-arrow'%3E%3Cpath d='M27.2391 18.2409C27.0464 18.085 26.952 17.8348 26.952 17.5806C26.952 17.3263 27.0482 17.0769 27.2404 16.8846L31.1394 12.9872H2.4843C1.94088 12.9872 1.5 12.5443 1.5 11.966C1.5 11.4574 1.94088 11.0186 2.4843 11.0186H31.1394L27.242 7.1212C26.8575 6.73671 26.8575 6.11394 27.242 5.72924C27.6265 5.34455 28.2493 5.34475 28.634 5.72924L34.2116 11.3069C34.5961 11.6914 34.5961 12.3142 34.2116 12.6989L28.634 18.2765C28.248 18.6592 27.6246 18.6592 27.2391 18.2409Z' fill='currentColor'/%3E%3C/svg%3E%0A",
    'arrow-small':
      "data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M31.7062 16.7063C32.0938 16.3188 32.0938 15.6813 31.7062 15.2938L22.7062 6.29375C22.3187 5.90625 21.6813 5.90625 21.2938 6.29375C20.9062 6.68125 20.9062 7.31875 21.2938 7.70625L28.5875 15H1C0.45 15 0 15.45 0 16C0 16.55 0.45 17 1 17H28.5875L21.2938 24.2938C20.9062 24.6813 20.9062 25.3188 21.2938 25.7063C21.6813 26.0938 22.3187 26.0938 22.7062 25.7063L31.7062 16.7063Z' fill='currentColor'/%3E%3C/svg%3E%0A",
    'logo-flag':
      "data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 23 24' fill='none' xmlns='http://www.w3.org/2000/svg' id='ico-logo-flag'%3E%3Cpath d='M22.7586 7.52531C21.0915 6.74569 19.2683 5.93021 15.828 5.8016C7.98976 5.50865 4.08552 12.893 4.08552 12.893C4.08552 12.893 8.61594 8.75954 13.8328 8.87555C17.3839 8.95448 18.7801 9.83995 20.4853 10.664L22.7586 7.52531Z' fill='currentColor' /%3E%3Cpath d='M18.7853 12.8917C17.0872 12.0966 15.2599 11.2471 11.7425 11.1155C3.90424 10.8227 0 18.2069 0 18.2069C0 18.2069 4.53042 14.0735 9.7473 14.1895C13.3757 14.2702 14.7537 15.1929 16.511 16.0318L18.7853 12.8917Z' fill='currentColor' /%3E%3C/svg%3E%0A",
    'caret':
      "data:image/svg+xml,%3Csvg viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg' id='ico-caret'%3E%3Cpath d='M14.7052 21.977C15.4026 22.6743 16.5351 22.6743 17.2325 21.977L26.1591 13.0504C26.8565 12.353 26.8565 11.2204 26.1591 10.523C25.4617 9.82565 24.3292 9.82565 23.6318 10.523L15.9661 18.1887L8.30038 10.5286C7.60299 9.83123 6.47043 9.83123 5.77304 10.5286C5.07565 11.226 5.07565 12.3586 5.77304 13.056L14.6996 21.9825L14.7052 21.977Z' fill='currentColor' /%3E%3C/svg%3E%0A",
  ),
  $inline-icons
);

$icon-sizes: (
  48: 48px,
  60: 60px,
  64: 64px,
  68: 68px,
  72: 72px,
);

$icon-directions: (
  0: 0deg,
  90: 90deg,
  180: 180deg,
  270: 270deg,
);

// transitions
$btn-icon-transition: transform 0.2s ease-in-out;

// forms
$form-label-font-size: px-to-rem(14px);
$input-border-radius: 0;
$input-border-width: 1px;
$input-border-color: transparent;
$input-focus-border-color: $primary;
$input-padding-y: $grid-gutter-width * 0.5;
$input-padding-x: $grid-gutter-width * 0.5;
$input-font-size: px-to-rem(18px);
$input-placeholder-color: $grey-700;

// shadow
$box-shadow: var(--shadow-left, 0) var(--shadow-bottom, #{$grid-gutter-width * 0.5})
  var(--shadow-blur, #{$grid-gutter-width * 1.25}) var(--shadow-spread, 0)
  rgba(var(--bs-black-rgb), var(--shadow-opacity, 0.08));

// badges
$badge-font-size: px-to-rem(16px);
$badge-font-weight: 400;
$badge-padding-y: $grid-gutter-width * 0.25;
$badge-padding-x: $grid-gutter-width * 0.5;
$badge-border-radius: $grid-gutter-width * 1.5;

// tables
$table-striped-order: even;

// transitions
$transition-collapse: height 0.2s ease-in-out;
