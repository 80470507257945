.cta {
  h2 {
    --min-size: var(--h3-min-size);
    --max-size: var(--h3-max-size);
  }

  @include media-breakpoint-up(xl) {
    --cta-icon-width: 64px;
  }

  @include media-breakpoint-up(ultrawide) {
    --cta-icon-width: 72px;
  }

  &__icon {
    flex: 1 0 var(--cta-icon-width);
    width: var(--cta-icon-width);
    height: var(--cta-icon-width);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
