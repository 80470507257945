.pageheader {
  @include media-breakpoint-up(md) {
    & > div {
      min-height: calc(var(--picture-height) - #{$grid-gutter-width * 1.25});
    }
  }

  @include media-breakpoint-up(xl) {
    & > div {
      min-height: calc(var(--picture-height) - #{$grid-gutter-width * 2});
    }
  }

  p {
    --bs-body-font-size: #{px-to-rem(20px)};

    @include media-breakpoint-up(xl) {
      --bs-body-font-size: #{px-to-rem(22px)};
    }
  }

  // will be set via js due to absolute positioning
  min-height: var(--picture-height);

  &__content {
    padding-top: calc(var(--breadcrumb-height) + #{$grid-gutter-width * 1.5});

    @include media-breakpoint-up(xl) {
      padding-top: calc(var(--breadcrumb-height) + #{$grid-gutter-width * 1.75});
    }
  }
}
