.modal {
  &.show {
    backdrop-filter: blur(2px);
  }

  &-backdrop.show {
    --bs-backdrop-opacity: 0.1;
  }

  @include media-breakpoint-only(md) {
    &-xl {
      --bs-modal-width: 700px;
    }
  }

  &-content {
    --bs-modal-border-radius: 0;

    --shadow-opacity: 0.14;
    --shadow-blur: #{$grid-gutter-width * 1.75};
    --shadow-bottom: #{$grid-gutter-width * 0.75};
  }
}
