@use 'sass:list';
@use 'sass:math';

:root {
  // general
  --container-max-width: 100%;

  @each $breakpoint, $maxWidth in $container-max-widths {
    @if ($breakpoint != 'sm') {
      @include media-breakpoint-up($breakpoint) {
        --container-max-width: #{$maxWidth};
      }
    }
  }

  --text-wrap: normal;

  // navigation
  --navigation-bg: transparent;
  --navigation-padding-x: 0;
  --navigation-padding-y: 0;
  --navigation-font-size: #{px-to-rem(20px)};

  --breadcrumb-bg: transparent;

  // skew
  --skew: 0;
  --skew-negative: calc(var(--skew) * -1);

  --skew-overlay-background: #{$white};
  --skew-height: 16px;

  --skew-clip-path: polygon(0 0, 0 100%, calc(100% + #{$grid-gutter-width}) 0);
  --skew-transform: -1px;

  @include media-breakpoint-up(md) {
    --skew-height: 24px;
  }

  @include media-breakpoint-up(xl) {
    --skew-height: 32px;
  }

  @include media-breakpoint-up(ultrawide) {
    --skew-height: 48px;
  }

  // grid stuff
  --full-width-grid-color-1: #{__get($theme-colors, 'action-1')};
  --full-width-grid-color-2: #{__get($theme-colors, 'white')};
  --full-width-grid-margin: #{calc(var(--bs-gutter-x) * 0.5)};

  // headings
  --container-min: 320;
  --container-max: #{math.div(
      list.nth(list.nth($container-max-widths, -1), -1),
      list.nth(list.nth($container-max-widths, -1), -1) * 0 + 1
    )};
  --min-size: 16;
  --max-size: 64;

  @each $heading, $settings in $headings-font-settings {
    --h#{$heading}-min-size: #{__get($settings, 'min')};
    --h#{$heading}-max-size: #{__get($settings, 'max')};
  }

  // icons
  --bs-btn-icon-gap: #{$grid-gutter-width * 0.25};
  --bs-btn-icon: #{$primary};
  --bs-btn-icon-width: 30px;
  --bs-btn-icon-height: 20px;
  --bs-btn-icon-transition: calc(var(--bs-btn-icon-gap) * 0.25);

  --cta-icon-width: 48px;

  // blockquote
  --blockquote-font-size: #{px-to-rem(24px)};
  --blockquote-font-weight: #{$font-weight-bold};

  // card teaser big
  // placeholder, we need this to prevent layoout shifts
  --card-big-offset: 411px;

  @include media-breakpoint-up(md) {
    --card-big-offset: 184px;
  }

  @include media-breakpoint-up(xl) {
    --card-big-offset: 147px;
  }

  @include media-breakpoint-up(ultrawide) {
    --card-big-offset: 134px;
  }
}
