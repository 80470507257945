a {
  text-underline-offset: 4px;

  &[href*='http'] {
    word-break: break-all;
  }

  &[class*='-flex'] {
    &:has(> svg) {
      svg {
        flex: 0 0 24px;
      }
    }
  }
}
