.form {
  &-select {
    border-color: __get($theme-colors, 'action-4');

    &.custom-arrow {
      appearance: none;
      font-weight: $font-weight-bold;
      background-image: url(inline-svg('caret'));
      background-size: 24px;
    }

    &:invalid {
      color: __get($theme-colors, 'action-5');
    }
  }

  &-label {
    font-weight: 500;
  }

  &-control {
    border-color: __get($theme-colors, 'action-4');
  }

  &-check {
    padding-left: 0;

    &-label {
      display: flex;
      flex-direction: row;
      column-gap: $grid-gutter-width * 0.5;
      align-items: flex-start;

      input[type='checkbox'] {
        appearance: none;
        width: 22px;
        height: 22px;
        border-radius: 4px;
        border: 2px solid black;
        flex-shrink: 0;
        transition: box-shadow 0.15s ease-in-out;

        &:focus-visible {
          box-shadow: $input-focus-box-shadow;
          outline: none;
        }

        &:checked {
          background: url(inline-svg('ico-check-mark', __get($theme-colors, 'black'))) center no-repeat;
          background-size: 16px;
        }
      }

      span {
        font-size: px-to-rem(18px);
      }
    }
  }

  &-framework {
    .form-group {
      margin-bottom: $grid-gutter-width * 0.75;
    }

    .actions {
      margin-top: $grid-gutter-width * 0.75;
    }

    .field-h {
      position: absolute;
      margin: 0 0 0 -999em;
    }

    textarea {
      min-height: calc(1.5em + 10rem + calc(1px * 2));
    }
  }
}
