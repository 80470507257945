.icon {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @each $icon, $size in $icon-sizes {
      @if $breakpoint == 'xs' {
        &-#{$icon} {
          width: $size;
          height: $size;
          flex: 0 0 $size;
          aspect-ratio: 1 / 1;
        }
      } @else {
        &-#{$breakpoint}-#{$icon} {
          @include media-breakpoint-up($breakpoint) {
            width: $size;
            height: $size;
            flex: 0 0 $size;
            aspect-ratio: 1 / 1;
          }
        }
      }
    }

    @each $direction, $value in $icon-directions {
      @if $breakpoint == 'xs' {
        &-direction-#{$direction} {
          rotate: $value;

          @if $direction == 180 {
            transform: translateY(-1px);
          }
        }
      } @else {
        &-direction-#{$breakpoint}-#{$direction} {
          @if $direction == 180 {
            transform: translateY(-1px);
          }

          @include media-breakpoint-up($breakpoint) {
            rotate: $value;
          }
        }
      }
    }
  }
}
