main {
  margin-top: calc(var(--breadcrumb-height) * -1);
  scroll-margin-top: 72px;

  @include media-breakpoint-up(xl) {
    // @TODO
    // this one is also set at the moment in the fluid files with inlining css
    // due to browser compatibility, we might remove this later this year, when
    // firefox is implementing the has selector
    margin-top: calc(var(--breadcrumb-height-xl) * -1);

    scroll-margin-top: 150px;
  }

  #nav-breadcrumb + &:has(> .frame-type-theme_page_header) {
    --breadcrumb-height: 72px;

    @include media-breakpoint-up(xl) {
      --breadcrumb-height: 107px;
    }
  }
}
