.news-filter {
  --news-filter-bg: #{__get($theme-colors, 'white')};
  --news-filter-font-size: #{px-to-rem(18px)};
  --news-filter-svg: 20px;
  --news-filter-btn-width: 115px;
  --news-filter-checkmark-size: 20px;

  @include media-breakpoint-up(xl) {
    --news-filter-font-size: #{px-to-rem(22px)};
    --news-filter-svg: 24px;
    --news-filter-checkmark-size: 24px;
  }

  &__select {
    --bs-border-color: #{__get($theme-colors, 'action-4')};

    flex: 1 1 calc(50% - var(--news-filter-btn-width));
    position: relative;

    @include media-breakpoint-up(md) {
      width: calc(50% - var(--news-filter-btn-width));
    }

    button {
      background: var(--news-filter-bg);
      width: 100%;
      height: 100%;
      border: none;
      font-size: var(--news-filter-font-size);
      position: relative;
      z-index: 20;

      svg {
        width: var(--news-filter-svg);
        height: var(--news-filter-svg);
      }

      &[aria-expanded='true'] {
        z-index: 20;
        box-shadow: $focus-ring-box-shadow;
      }

      & + div {
        z-index: 19;
        max-height: 50dvh;
        overflow: scroll;
      }
    }

    &-actives {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-inline-end: $grid-gutter-width * 0.5;

      span:not(:last-child) {
        &:after {
          content: ', ';
        }
      }
    }
  }

  &__items {
    position: absolute;
    z-index: 9;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--news-filter-bg);
    outline: 1px solid var(--bs-border-color);

    &-list {
      label {
        cursor: pointer;

        &:hover {
          background-color: __get($theme-colors, 'action-1');
        }
      }
    }

    &-item {
      font-size: var(--news-filter-font-size);

      input {
        position: absolute;
        appearance: none;

        &:focus-visible {
          + span {
            @include focus-ring-style;
            --focus-ring-outline-offset: -2px;
          }
        }

        + span {
          padding: px-to-rem(12px) $grid-gutter-width * 0.5;

          @include media-breakpoint-up(xl) {
            padding: $grid-gutter-width * 0.5 $grid-gutter-width * 0.75;
          }
        }

        &:checked + span {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;

          &:after {
            content: '';
            width: var(--news-filter-checkmark-size);
            height: var(--news-filter-checkmark-size);
            background-image: url(inline-svg('ico-check-mark'));
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }

  &__button {
    --news-filter-btn-width: auto;

    @include media-breakpoint-up(md) {
      --news-filter-btn-width: 115px;
    }

    flex: 1 0 var(--news-filter-btn-width);
  }
}
