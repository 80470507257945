.frame-type-menu_pages,
.frame-type-menu_subpages {
  h2 {
    --headings-margin: #{$grid-gutter-width * 0.5};

    @include media-breakpoint-up(xl) {
      --headings-margin: #{$grid-gutter-width * 0.75};
    }
  }
}
