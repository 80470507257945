@use 'sass:math';

.card {
  &-small {
    .subheader {
      @include subheading;
    }

    h2 {
      --text-wrap: balance;
    }

    h3,
    .h3 {
      --container-max: 1200;
      --min-size: 22;
      --max-size: 26;
      --headings-margin: #{$grid-gutter-width * 0.25};
    }

    &__cards {
      &-card {
        flex: 1 0 calc(#{percentage(math.div(6, 12))} - #{$grid-gutter-width});
      }
    }
  }

  &-big {
    @include media-breakpoint-up(xl) {
      --bs-body-font-size: #{px-to-rem(20px)};
    }

    --card-big-offset-calc: calc(var(--card-big-offset) * 2 - #{$grid-gutter-width * 2});
    --card-big-heading-width: 100%;
    --card-big-subheading: #{px-to-rem(14px)};
    --card-big-image-overlay: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0) #{percentage(math.div(10, 12))}
    );

    --card-big-box-row-image: 1;
    --card-big-box-row-div: 2;
    --card-big-box-column-image: 1 / -1;
    --card-big-box-image-width: 160px;
    --card-big-box-image-height: 120px;
    --card-big-box-column-div: 1 / -1;

    --bs-body-color: #{__get($theme-colors, 'white')};
    --bs-body-font-size: #{px-to-rem(18px)};

    h2 {
      --min-size: #{__get($headings-font-settings, 1 'min')};
      --max-size: #{__get($headings-font-settings, 1 'max')};
    }

    h3 {
      --min-size: 22;
      --max-size: 26;
      --headings-margin: #{$grid-gutter-width * 0.25};
    }

    h4 {
      --container-max: 1200;
      --min-size: 18;
      --max-size: 20;
    }

    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto var(--card-big-offset-calc, var(--card-big-offset, auto)) auto;

    @include media-breakpoint-up(md) {
      --card-big-offset-calc: calc(var(--card-big-offset) * 2 - #{$grid-gutter-width});
      --card-big-heading-width: #{percentage(math.div(7, 12))};
      --card-big-image-overlay: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) #{percentage(math.div(9, 12))}
      );
    }

    @include media-breakpoint-up(xl) {
      --card-big-offset-calc: var(--card-big-offset);
      --card-big-heading-width: #{percentage(math.div(6, 12))};
      --card-big-subheading: #{px-to-rem(16px)};
      --card-big-box-row-image: 1 / -1;
      --card-big-box-row-div: 1 / -1;
      --card-big-box-column-image: 1;
      --card-big-box-column-div: 2;
    }

    @include media-breakpoint-up(ultrawide) {
      --card-big-heading-width: #{percentage(math.div(5, 12))};
    }

    &__heading {
      width: var(--card-big-heading-width);

      * {
        color: var(--bs-body-color);
      }

      &-sub {
        font-weight: $headings-font-weight;
        letter-spacing: 1.6px;
        font-size: var(--card-big-subheading);
      }
    }

    &__image {
      grid-column: 1;
      grid-row: 1;
      position: relative;

      @include media-breakpoint-up(ultrawide) {
        max-width: var(--container-max-width);
      }

      justify-self: center;

      &:before {
        content: '';
        background: var(--card-big-image-overlay);
        position: absolute;
        inset: 0;
        z-index: 1;
      }
    }

    &__content {
      grid-column: 1;
      grid-row: 1 / -2;
      z-index: 1;

      &-box {
        flex: 1 0 calc(50% - #{$grid-gutter-width});
        display: grid;
        grid-template-rows: var(--card-big-box-image-height) 1fr;
        grid-template-columns: var(--card-big-box-image-width) 1fr;

        font-size: var(--bs-body-font-size);

        & > figure {
          grid-row: var(--card-big-box-row-image);
          grid-column: var(--card-big-box-column-image);
        }

        & > div {
          grid-row: var(--card-big-box-row-div);
          grid-column: var(--card-big-box-column-div);
        }

        img {
          max-height: var(--card-big-box-image-height);
        }
      }
    }

    &__blockquote {
      grid-row: -1;
    }
  }
}
