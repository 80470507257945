@use 'sass:math';

.textmedia {
  &__text {
    @include bodytext;

    h3 {
      --min-size: 24;
    }

    p:has(.btn) {
      margin-top: $grid-gutter-width * 0.5;

      @include media-breakpoint-up(ultrawide) {
        margin-top: $grid-gutter-width;
      }
    }
  }

  &__heading {
    h3 {
      @include subheading;
    }
  }
}
