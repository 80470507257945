figure.table {
  @extend .table-responsive;

  [tabindex='0'] {
    --focus-ring-outline-offset: -2px;
  }
}

table.table {
  --bs-table-padding-x: #{$grid-gutter-width * 0.75};
  --bs-table-padding-y: #{$grid-gutter-width * 0.5};
  --bs-border-color: var(--bs-action-4);

  @include media-breakpoint-up(md) {
    --bs-table-padding-y: #{$grid-gutter-width * 0.75};
  }

  --bs-table-striped-bg: var(--bs-action-1);

  > :not(caption) > * > * {
    padding: var(--bs-table-padding-y) var(--bs-table-padding-x);
    font-size: var(--bs-body-font-size);
  }

  thead {
    --bs-table-bg: var(--bs-action-1);
    --bs-border-width: 2px;
  }
}
