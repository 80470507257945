.pagination {
  &-list {
    &__item {
      background-color: __get($theme-colors, 'action-1');
      display: inline-flex;
      width: 43px;
      font-size: px-to-rem(18px);
      height: min-content;
      aspect-ratio: 1/1;

      &:hover {
        background-color: __get($theme-colors, 'action-4');
      }

      &.active {
        background-color: __get($theme-colors, 'primary');
        &:hover {
          background-color: $red-800;
        }
      }

      &-link {
        --bs-link-color-rgb: #{__get($theme-colors, 'bodycolor')};
        text-decoration: none;
        line-height: 1.5;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          --bs-link-color-rgb: #{__get($theme-colors, 'bodycolor')};
        }

        .active & {
          color: __get($theme-colors, 'white');
          cursor: default;
        }
      }

      &-select {
        font-size: px-to-rem(18px);
      }
    }
  }
}
