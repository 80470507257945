:root {
  --brand-color: #{__get($theme-colors, 'brand-red')};
  --brand-font-color: #{color-contrast(__get($theme-colors, 'brand-red'))};
  --brand-list-point: #{url(inline-svg('logo-flag'))};
}

.brand-color {
  &-community {
    --brand-color: #{__get($theme-colors, 'brand-red')};
    --brand-font-color: #{$white};

    .list-brand-color {
      --brand-list-point: #{url(inline-svg('logo-flag', __get($theme-colors, 'brand-red')))};
    }
  }

  &-federation {
    --brand-color: #{__get($theme-colors, 'brand-green')};
    --brand-font-color: #{$white};

    .list-brand-color {
      --brand-list-point: #{url(inline-svg('logo-flag', __get($theme-colors, 'brand-green')))};
    }
  }

  &-sport {
    --brand-color: #{__get($theme-colors, 'brand-blue')};
    --brand-font-color: #{color-contrast(__get($theme-colors, 'brand-blue'))};

    .list-brand-color {
      --brand-list-point: #{url(inline-svg('logo-flag', __get($theme-colors, 'brand-blue')))};
    }
  }

  &-service {
    --brand-color: #{__get($theme-colors, 'brand-orange')};
    --brand-font-color: #{$white};

    .list-brand-color {
      --brand-list-point: #{url(inline-svg('logo-flag', __get($theme-colors, 'brand-orange')))};
    }
  }
}

.bg-brand {
  background: var(--brand-color);
  color: var(--brand-font-color);
}
