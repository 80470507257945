ul {
  font-size: var(--bs-body-font-size);

  p + & {
    margin-top: $grid-gutter-width * 0.5;
  }

  .bodytext & {
    --gap: #{$grid-gutter-width * 0.75};
    padding: 0;
    display: flex;
    gap: var(--gap);
    flex-direction: column;

    &.list-brand-color li::marker {
      --brand-list-marker: var(--brand-color);
    }

    > li {
      --li-padding-left: #{$grid-gutter-width * 1.5};
      padding-left: var(--li-padding-left);
      list-style: none;
      position: relative;
      hyphens: auto;

      @include media-breakpoint-up(xl) {
        --li-padding-left: #{$grid-gutter-width * 2};
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        background: var(--brand-list-point) center no-repeat;
        background-size: cover;

        width: 32px;

        @include media-breakpoint-up(xl) {
          width: 40px;
        }
        aspect-ratio: 1/1;
      }

      ul {
        margin-top: $grid-gutter-width * 0.5;
        margin-left: $grid-gutter-width;

        li {
          &::marker {
            color: var(--brand-list-marker, #{__get($theme-colors, 'brand-red')});
          }

          list-style: square;
          padding-left: $grid-gutter-width * 0.5;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .frame-type-theme_bullet_list & {
    margin-bottom: 0;

    --column-count: 1;
    --column-gap: #{$grid-gutter-width * 0.75};

    @include media-breakpoint-up(xl) {
      display: block;
      --column-count: 2;
      --column-gap: #{$grid-gutter-width};
    }

    column-count: var(--column-count);
    column-gap: var(--column-gap);

    li {
      break-inside: avoid;

      @include media-breakpoint-up(xl) {
        margin-bottom: $grid-gutter-width * 0.75;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .modal &,
  .dialog & {
    --gap: #{$grid-gutter-width * 0.5};
    padding-left: $grid-gutter-width;

    > li {
      --li-padding-left: #{$grid-gutter-width * 0.25};
      list-style: square;

      &:before {
        visibility: hidden;
      }

      &::marker {
        color: var(--brand-list-marker, #{__get($theme-colors, 'brand-red')});
        font-size: px-to-rem(24px);
      }

      ul {
        margin-left: 0;
        margin-top: $grid-gutter-width * 0.25;

        li {
          padding-left: 0;
        }
      }
    }
  }
}
