ol {
  font-size: var(--bs-body-font-size);
  margin-left: $grid-gutter-width * 1.25;

  p + & {
    margin-top: $grid-gutter-width * 0.5;
  }

  .bodytext & {
    --gap: #{$grid-gutter-width * 0.75};
    padding: 0;
    display: flex;
    gap: var(--gap);
    flex-direction: column;

    > li {
      --li-padding-left: #{$grid-gutter-width * 0.25};
      padding-left: var(--li-padding-left);
      position: relative;
      hyphens: auto;

      @include media-breakpoint-up(xl) {
        --li-padding-left: #{$grid-gutter-width * 0.75};
      }
    }
  }
}
