.frame-type-sports_all {
  .persons-card {
    @include motion-safe {
      transition: all 0.2s ease-in-out;
    }

    &__body {
      &-title {
        --min-size: 22;
        --max-size: 26;
        --headings-margin: #{$grid-gutter-width * 0.25};
      }

      &-categories {
        --bs-body-font-size: #{px-to-rem(18px)};

        line-height: 1.3;
        font-weight: 500;
        color: #{$grey-900};
      }

      &-email {
        font-size: px-to-rem(18px);
        line-height: 1.5;
        word-break: break-word;
      }
    }
  }
}
