.badge {
  height: fit-content;
  line-height: 1.2;

  &-xl {
    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
    }
  }

  &.text-bg-outline-primary {
    box-shadow: inset 0 0 0px 1px __get($theme-colors, 'primary');
    color: __get($theme-colors, 'primary');

    &:hover {
      color: __get($theme-colors, 'white');
      background-color: __get($theme-colors, 'primary');
    }
  }

  &:is(a) {
    display: flex;
    align-items: center;
    gap: $grid-gutter-width * 0.25;

    @include media-breakpoint-up(xl) {
      font-size: px-to-rem(18px);
    }

    &.text-bg-action-1 {
      &:hover {
        --bs-action-1-rgb: var(--bs-action-3-rgb);
      }
    }

    &.text-bg-white {
      &:hover {
        --bs-white-rgb: var(--bs-action-3-rgb);
      }
    }

    &:focus-visible {
      --focus-ring-outline-offset: -2px;
    }
  }
}
