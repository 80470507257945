[data-slides-fullwidth] {
  --slides-width: 240px;
  --text-wrap: balance;

  h3 {
    --min-size: 22;
    --max-size: 26;

    // FOR TESTING PURPOSE ONLY
    // in safari / chrome on IOS there is a bug where hyphens are not applied, so the content is getting bigger
    // -> img are shown in diff sizes
    word-break: break-word;
  }

  &.events_startsite {
    --focus-ring-outline-offset: -2px;
  }

  @include media-breakpoint-up(md) {
    --slides-width: 276px;

    &.events_startsite {
      --slides-width: 240px;
    }
  }

  @include media-breakpoint-up(xl) {
    --slides-width: 336px;

    &.events_startsite {
      --slides-width: 306px;
    }
  }

  @include media-breakpoint-up(ultrawide) {
    --slides-width: 440px;

    &.events_startsite {
      --slides-width: 361px;
    }

    --text-wrap: normal;
  }

  .full-width-grid__slider.no-slider & {
    .arrows {
      @extend .d-none;
    }
  }

  .slides {
    @include motion-safe {
      transition: transform 0.2s ease-in-out;
    }

    gap: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(md) {
      gap: $grid-gutter-width * 0.75;
    }

    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;

    @include media-breakpoint-up(xl) {
      overflow-x: visible;
      gap: $grid-gutter-width;
    }

    &__item {
      flex: 0 0 var(--slides-width);
      scroll-snap-align: center;

      @include motion-safe {
        transition: all 0.2s ease-in-out;
      }

      time,
      a {
        @include media-breakpoint-up(xl) {
          font-size: #{px-to-rem(18px)};
        }
      }

      time {
        line-height: var(--bs-body-line-height);
      }

      a {
        line-height: 1.2;
      }

      &.active:not(.first) {
        @include shadow-hover;
      }
    }
  }
}

.frame-type-news_pi1 .skew .arrows {
  margin-bottom: $grid-gutter-width * 1.5;
}

.frame-type-news_pi1,
.frame-type-news_newsdetail {
  .arrows {
    button {
      &:hover {
        --bs-primary-rgb: var(--bs-action-6-rgb);
      }

      &:disabled svg {
        --bs-primary-rgb: var(--bs-action-3-rgb);
      }

      &:first-child {
        &:after {
          content: '';
          width: 1px;
          height: 100%;
          background: __get($theme-colors, 'action-3');
        }
      }
    }
  }
}
