@use 'sass:math';

.full-width-grid {
  --bs-gutter-x: #{$grid-gutter-width};
  --full-width-grid-margin: calc(var(--bs-gutter-x) * 0.5);

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns:
      minmax(var(--full-width-grid-margin), 1fr)
      minmax(0, var(--container-max-width))
      minmax(var(--full-width-grid-margin), 1fr);

    &:before {
      grid-row: 1;
      grid-column: 1;
      content: '';
      background: var(--full-width-grid-color-1);
    }

    &:after {
      grid-row: 1;
      grid-column: 3 / -1;
      content: '';
      background: var(--full-width-grid-color-2);
    }
  }

  @include media-breakpoint-up(ultrawide) {
    --full-width-grid-margin: 0;
  }

  &__content {
    @include media-breakpoint-up(md) {
      background: var(--full-width-grid-color-1);
      grid-row: 1;
      grid-column: 1 / 3;

      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr percentage(math.div(3, 12));

      @include media-breakpoint-up(xl) {
        grid-column: 2 / 3;
      }

      &:after {
        content: '';
        grid-row: 1;
        grid-column: 2/ -1;
        clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
        background: var(--full-width-grid-color-2);
      }

      & > div {
        grid-row: 1;
        grid-column: 1/-1;
        z-index: 1;
      }
    }

    &-text {
      background: __get($theme-colors, 'action-1');

      @include media-breakpoint-up(md) {
        background: none;
      }
    }
  }

  &__overlay {
    @include media-breakpoint-between(md, xl) {
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;

      .full-width-grid__content-text {
        z-index: 1;
      }

      .full-width-grid__content-text,
      .full-width-grid__content-image {
        grid-column: 1;
        grid-row: 1;
      }
    }

    //@include media-breakpoint-up(xl) {
    //  display: flex;
    //}
  }

  &__slider {
    --full-width-grid-color-1: #{$white};

    @each $colorName, $colorValue in $theme-colors {
      &.bg-#{$colorName} {
        --full-width-grid-color-1: #{$colorValue};
        --full-width-grid-color-2: #{$colorValue};
      }
    }

    &:not(.no-slider) {
      overflow-x: hidden;
      padding-bottom: 4px;
    }

    .full-width-grid__content {
      grid-column: 1 / -1;

      @include media-breakpoint-up(xl) {
        grid-column: 2 / 3;
      }
    }
  }

  &.frame-type-theme_page_header {
    //--full-width-grid-margin: #{$grid-gutter-width * 0.5};
    //--full-width-grid-color-2: var(--full-width-grid-color-1);
  }
}
